import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { notification, message, Select } from 'antd';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { fetchProfile, removeProfile, makePaymentNMI } from '../../redux/actions/profileActions';
import Href from '../../shared/Components/Href';
import ProfileDangerousActions from './ProfileDangerousActions';
import UpcomingInvoice from './UpcomingInvoice';
import PromotionCode from './PromotionCode';
import ReferralProgram from './ReferralProgram';
import SupportBlock from './SupportBlock';
import EmailAddress from './EmailAddress';
import Balance from './Balance';
import APIToken from './APIToken';

const { Option } = Select;

const appLanguages = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'es', label: 'Español' },
  { code: 'fr', label: 'Français' },
  { code: 'it', label: 'Italiano' },
  { code: 'br', label: 'Português (Brasil)' },
  { code: 'ru', label: 'Русский' },
  { code: 'tr', label: 'Türkçe' },
  { code: 'uk', label: 'Українська' },
  { code: 'zh', label: '中文' },
  { code: 'ar', label: 'العربية' },
  { code: 'hi', label: 'हिन्दी' },
  { code: 'jp', label: '日本語' },
  { code: 'ko', label: '한국어' },
  { code: 'th', label: 'ไทย' },
  { code: 'vi', label: 'Tiếng Việt' }
];

function Space() { return (<><br /><br /><br /></>); }

export default function Profile({ authForwarded }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { search = {} } = location;
  const { profile: authProfile } = authForwarded || {};
  const { emailVerified, email, roles = [], provider, userId } = authProfile || {};
  const isSupport = roles.includes('support');
  const isTenant = roles.includes('tenant');
  const { secondary_tab, deposite, deposited } = queryString.parse(search);
  const isSecondaryTab = secondary_tab && secondary_tab === 'true';

  const profile = useSelector(state => state.profileReducer.profile);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { account_status, api_token, coupon = {}, upcoming_invoice = {}, referred } = profile;
  const { name: couponName } = coupon;
  const accountNotValid = account_status !== 'valid';

  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);

  useEffect(() => {
    document.title = t('title.profile');

    if (deposite) {
      handleOnMakePayment(parseInt(deposite), isSecondaryTab);
    } else if (deposited === 'true') {
      onFetchProfile();
      if (isSecondaryTab) {
        message.success(t('profile.message.returnToPreviousTab'), 30);
        setTimeout(() => window.close(), 1000);
      }
    }
    else {
      onFetchProfile();
    }
  }, []);

  async function onPaymentMade() {
    await onFetchProfile();
    if (isSecondaryTab) {
      setTimeout(() => window.close(), 1000);
    }

  }

  async function handleOnMakePayment(amount, secondaryTab) {
    onMakePayment(amount, secondaryTab);
  }

  function onMakePayment(amount, secondaryTab = false) {
    dispatch(makePaymentNMI(amount, secondaryTab));
  }

  function onFetchProfile() {
    return dispatch(fetchProfile());
  }

  async function onPasswordChangeRequest() {
    setPasswordChangeRequested(true);
    const cb = function () {
      notification.success({
        message: t('profile.messages.checkEmailForPasswordChange'),
        duration: 7
      });
    };
    await authForwarded.requestPasswordChange(cb);
  }

  function onProfileRemove() {
    dispatch(removeProfile(navigate));
  }

  function onEmailChanged() {
    authForwarded.logout();
  }

  function changeLanguage(value) {
    i18n.changeLanguage(value);

    const updatedUrl = value !== 'en' ? `${window.location.pathname}?ln=${value}` : window.location.pathname;

    window.location.href = updatedUrl;
  }

  return <>
    {!isTenant && <Balance loading={loading} profile={profile} onPaymentMade={onPaymentMade} emailVerified={emailVerified} />}
    <h3><Trans i18nKey='title.apiToken' /></h3>
    <p>
      <Trans i18nKey='profile.apiToken.description' />
      <Href external href='/api-docs'>
        <Trans i18nKey='title.apiDocs' />
      </Href>
      <Trans i18nKey='profile.apiToken.moreInfo' />
    </p>
    <APIToken loading={loading} token={api_token} accountNotValid={accountNotValid} />
    {account_status === 'no_default_source' && <>
      <br /><br />
      <button
        type='button'
        className='link-button error-link-button'
      >
        <Trans i18nKey='profile.buttonError' />
      </button>
    </>}
    <Space />

    {!isEmpty(upcoming_invoice) && <>
      <h3><Trans i18nKey='title.upcomingInvoice' /></h3>
      <UpcomingInvoice loading={loading} value={upcoming_invoice} couponName={couponName} isSupport={isSupport} />
      <Space />
    </>}

    <h3><Trans i18nKey='profile.referralProgram.title' /></h3>
    <p>
      <Trans i18nKey='profile.referralProgram.description1' />
      <strong>25%</strong>
      <Trans i18nKey='profile.referralProgram.description2' />
    </p>
    <ReferralProgram userId={userId} disabled={loading} referred={referred} />
    <Space />

    <h3> <Trans i18nKey='title.promotionCode' /></h3>
    <PromotionCode existingCouponName={couponName} />
    <Space />
    <h3><Trans i18nKey='title.emailAddress' /></h3>
    <EmailAddress
      value={email}
      verified={emailVerified}
    />
    <Space />

    <h3><Trans i18nKey='title.language' /></h3>
    <Select
      showSearch
      style={{ width: 150 }}
      defaultValue={i18n.language || 'en'}
      optionFilterProp='children'
      onChange={changeLanguage}
    >
      {appLanguages.map(r => <Option key={r.code} value={r.code}>{r.label}</Option>)}
    </Select>
    <Space />

    {isSupport && <>
      <SupportBlock profile={authProfile} loading={loading} auth={authForwarded} />
      <Space />
    </>}

    <ProfileDangerousActions
      loading={loading}
      passwordChangeRequested={passwordChangeRequested}
      onPasswordChangeRequest={onPasswordChangeRequest}
      onEmailChanged={onEmailChanged}
      onRemove={onProfileRemove}
      authProvider={provider}
    />
  </>;
}

Profile.propTypes = {
  authForwarded: PropTypes.object.isRequired,
  auth: PropTypes.object,
};
