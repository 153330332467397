import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Moment from 'react-moment';
import { notification, Card, Button, Space, Col, Row, Statistic, Typography, Checkbox, List, InputNumber, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GoogleSheetsIcon } from '../../assets/icons';
import jwt_decode from 'jwt-decode';
import config from 'react-global-configuration';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';

import { removeKeys } from '../../helpers/common';
import { dynamicallyCreateAnchorAndNavigate } from '../../utils/helper';
import { connectGoogleSheetsAndReturnCredentials, fetchIntegrations } from '../../redux/actions/profileActions';
import { isGoogleIntegrationExists } from '../../utils/utils';

import Href from '../../shared/Components/Href';
import ResultsTypeSelect from './ResultsTypeSelect';
import { useDispatch } from 'react-redux';

const { Meta } = Card;
const { Text } = Typography;
const MIN_CHUNK_SIZE = 50000;
const MAX_CHUNK_SIZE = 800000;

export default function CheckoutResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = location;
  const { token, fileType, quantity, units = 'rows' } = queryString.parse(search);
  const payload = jwt_decode(token);
  const { __expire_at, product_name, search_params } = payload || {};
  const expired = __expire_at ? parseInt(Date.now() / 1000) > __expire_at : false;

  const [type, setType] = useState(fileType);
  const [clicked, setClicked] = useState(false);
  const [inChunk, setInChunk] = useState(false);
  const [chunkSize, setChunkSize] = useState(quantity > MAX_CHUNK_SIZE * 3 ? MAX_CHUNK_SIZE : MIN_CHUNK_SIZE);
  const [isExporting, setIsExporting] = useState(false);
  const [exportUrls, setExportUrls] = useState([]);
  const [error, setError] = useState(null);

  const splitable = quantity > MIN_CHUNK_SIZE * 1.5;
  const chunks = [];
  if (inChunk) {
    for (let i = 0; i < quantity; i += chunkSize) {
      chunks.push(i);
    }
  }

  useEffect(() => {
    if (!token) {
      notification.error({ message: t('checkoutResults.emptyToken') });
    }
    dispatch(fetchIntegrations());
  }, []);

  function downloadCheckoutResults(chunkSkip, chunkQuantity) {
    if (chunkQuantity === quantity) setClicked(true);

    notification.success({
      message: t('checkoutResults.message', { fileType: type.toUpperCase() }),
      description: t('checkoutResults.description'),
      icon: <DownloadOutlined style={{ color: '#108ee9' }} />,
      duration: 8
    });

    const url = `${window.location.protocol}//${config.get('apiDomain')}/download-checkout-results?token=${token}&fileType=${type}&productName=${product_name}&chunkSkip=${chunkSkip}&chunkQuantity=${chunkQuantity}`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `results_${chunkSkip + 1}-${chunkQuantity}.${type}`);
    document.body.appendChild(link);
    link.click();

    if (chunkQuantity === quantity) setTimeout(() => setClicked(false), 30000);
  }

  function onBackToSearch() {
    const productUrl = product_name.toLowerCase() === 'places' ? 'local-businesses' : 'businesses';
    const updatedParams = removeKeys(search_params, 'export');
    delete updatedParams['os_id'];

    navigate(`/${productUrl}?${queryString.stringify(updatedParams)}`, { replace: true });
  }

  function onSetType(value) {
    setType(value);
    setClicked(false);
  }

  const exportToSpreadsheet = async (chunkSkip, chunkQuantity) => {
    setIsExporting(true);
    setError(null);

    try {
      const { search_params } = payload || {};
      const searchParamsSerialized = btoa(JSON.stringify(search_params));

      const initialUrl = `${window.location.protocol}//${config.get('apiDomain')}/download-checkout-results?token=${token}&fileType=spreadsheet&productName=${product_name}&search_params=${searchParamsSerialized}&chunkSkip=${chunkSkip}&chunkQuantity=${chunkQuantity}`;
      let url = initialUrl;

      if (!isGoogleIntegrationExists()) {
        const { access_token, refresh_token } = await connectGoogleSheetsAndReturnCredentials();
        url = `${initialUrl}&accessToken=${access_token}&refreshToken=${refresh_token}`;
      }

      let response = await axios.get(url);

      if (response.data.url === 'authorize') {
        const { access_token, refresh_token } = await connectGoogleSheetsAndReturnCredentials();
        url = `${initialUrl}&accessToken=${access_token}&refreshToken=${refresh_token}`;

        response = await axios.get(url);
      }

      if (response.data.urls.length > 1) {
        setExportUrls(response.data.urls);
        notification.info({
          message: t('title.info'),
          description: t('checkoutResults.messageChunksGoogle'),
          duration: 10,
        });
        return;
      }

      if (response.data.url) {
        notification.success({
          message: <a href={response.data.url} target='_blank' rel='noopener noreferrer'>Open on Google Sheets</a>,
          duration: 30, style: { width: 600 }
        });
        dynamicallyCreateAnchorAndNavigate(response.data.url);
      } else {
        setError('No URL returned from the server.');
      }
    } catch (error) {
      setError(error.message || 'Failed to export the file to Google Sheets.');
      notification.error({
        message: 'Export Failed',
        description: error.message || 'An unknown error occurred while exporting.',
        duration: 10,
      });
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <Card>
      <Meta
        title={t('title.downloadingResults')}
      />
      <br /><br />
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title={`${t('checkoutResults.amountOf')} ${t(`checkoutResults.${units}`)}`}
            value={quantity}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('checkoutResults.fileType')}
            value={type}
            formatter={(value) => <ResultsTypeSelect value={value} onChange={onSetType} style={{ width: 110 }} variant='borderless' />}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('checkoutResults.expireAt')}
            value={__expire_at}
            formatter={(value) => <Text type={expired ? 'danger' : null}>
              <Moment unix local format='YYYY-MM-DD HH:mm'>{value}</Moment>
              {expired && ` (${t('checkoutResults.expired')})`}
            </Text>}
          />
        </Col>
      </Row>

      <br /><br />
      <Space>
        <Button onClick={onBackToSearch}>{t('title.backToSearch')}</Button>
        <Button
          disabled={expired || clicked || inChunk}
          type='primary'
          title={t('checkoutResults.downloadResults', { fileType: type.toUpperCase() })}
          icon={<DownloadOutlined />}
          onClick={() => downloadCheckoutResults(0, quantity)}
        >{t('checkoutResults.downloadResultsButton', { fileType: type.toUpperCase() })}</Button>
        <Tooltip title={t('checkoutResults.googleSpreadsheet')}>
          <Space>
            <Button
              title={t('checkoutResults.downloadResults', { fileType: 'Google Spreadsheet' })}
              loading={isExporting}
              disabled={expired}
              icon={<GoogleSheetsIcon />}
              onClick={() => exportToSpreadsheet(payload.search_params.skip || 0, quantity)}
            />
          </Space>
        </Tooltip>
        {splitable && <Checkbox
          checked={inChunk}
          onChange={() => setInChunk(!inChunk)}
        >{t('checkoutResults.downloadInChunks')} </Checkbox>}
      </Space>
      {exportUrls.length > 1 && (
        <List
          style={{ marginTop: 20 }}
          dataSource={exportUrls}
          renderItem={(url, index) => (
            <List.Item >
              <Href href={url} external> <Trans i18nKey='checkoutResults.exportedLink' /> {index + 1}</Href>
            </List.Item>
          )}
        />
      )}
      {inChunk && <>
        <br /><br />
        <Space>
          {t('checkoutResults.maxRecordsPerFile')}
          <InputNumber
            min={MIN_CHUNK_SIZE}
            max={MAX_CHUNK_SIZE}
            step={50000}
            value={chunkSize}
            onChange={(v) => setChunkSize(v || MIN_CHUNK_SIZE)}
          />
        </Space>
        <br /><br />
        <List
          dataSource={chunks}
          renderItem={(start) => (
            <List.Item
              actions={[
                <Button
                  key='download'
                  type='primary'
                  disabled={expired}
                  title={t('checkoutResults.downloadResults', { fileType: type.toUpperCase() })}
                  icon={<DownloadOutlined />}
                  onClick={() => downloadCheckoutResults(start, chunkSize)}>
                  {t('checkoutResults.downloadChunk', {
                    start: (start + 1).toLocaleString(),
                    end: Math.min(start + chunkSize, quantity).toLocaleString(),
                    records: (Math.min(start + chunkSize, quantity) - start).toLocaleString(),
                    fileType: type.toUpperCase(),
                  })}
                </Button>
              ]}
            >
              {t('checkoutResults.row', {
                start: (start + 1).toLocaleString(),
                end: Math.min(start + chunkSize, quantity).toLocaleString(),
              })}
            </List.Item>
          )}
        />
      </>}
    </Card>
  );
}
