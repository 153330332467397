import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { GoogleSheetsIcon } from '../../assets/icons';

import { fetchIntegrations, removeIntegration, connectIntegration } from '../../redux/actions/profileActions';
import { isEmpty } from 'lodash';

const { Meta } = Card;

const cardStyle = { width: 510 };
const PageHeaderStyle = { border: '1px solid rgb(235, 237, 240)' };

export default function Integrations() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.profileReducer.loading);
  const integrations = useSelector(state => state.profileReducer.integrations);

  const { google = {} } = integrations;

  useEffect(() => {
    document.title = t('title.integrations');
    dispatch(fetchIntegrations());
  }, []);

  function onConnectGoogle() {
    dispatch(connectIntegration('google'));
  }

  function onRemoveIntegration(integration) {
    dispatch(removeIntegration(integration));
  }

  return (
    <Space direction='vertical' size='large' >
      <PageHeader
        style={PageHeaderStyle}
        title={t('title.integrations')}
        subTitle={t('integrations.description')}
      />

      <Card
        className='card-white-bg'
        style={cardStyle}
        loading={isLoading}
        actions={[
          isEmpty(google) ? <LinkOutlined key='connect-google'
            title={t('integrations.connect')}
            onClick={onConnectGoogle} /> :
            <DeleteOutlined key='delete'
              title={t('integrations.remove')}
              onClick={() => onRemoveIntegration('google')} />
        ]}
      >
        <Meta
          avatar={<GoogleSheetsIcon />}
          title='Google Sheets'
          description={t('integrations.googleDescription')}
        />
      </Card>
    </Space>
  );
}
