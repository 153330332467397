import React from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip, message } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

import { toArray, toTitleCase } from '../../utils/helper';
import GMBtypes from '../../shared/data/types.json';

const MAX_TAG_COUNT = 1;

const StyledButton = styled.button`
  padding-left: 6px;
  text-decoration: underline;
  padding-right: 1px;
`;

export default function SelectType({ value, onChange, size, loading, types, placeholder = 'Category', showQucikButtons }) {
  const arrayValue = toArray(value);
  const selectedLength = arrayValue.length;
  const title = selectedLength > MAX_TAG_COUNT ? arrayValue.join(', ') : null;
  const isMaxValues = selectedLength > 100;
  const { t } = useTranslation();

  function handleShowError() {
    message.error('The maximum amount of options selected');
  }

  return (
    <div>
      <Tooltip
        placement='right'
        mouseEnterDelay={0.3}
        title={title}
      >
        <Select
          allowClear
          autoFocus
          showSearch
          autoClearSearchValue={false}
          maxTagCount={MAX_TAG_COUNT}
          mode='multiple'
          tokenSeparators={[',', '\n']}
          style={{ minWidth: '170px' }}
          loading={loading}
          size={size}
          placeholder={t('filters.category')}
          value={value}
          onChange={onChange}
          optionFilterProp='children'
          {...(isMaxValues && { open: false, onDropdownVisibleChange: handleShowError })}
        >
          {(types || GMBtypes).map((r) => (
            <Option key={r} value={r}>{toTitleCase(r)}</Option>
          ))}
        </Select>
      </Tooltip>

      {showQucikButtons && <div>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(GMBtypes.slice(0, 100))}
        >
          Top 100,
        </StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(GMBtypes.slice(0, 200))}
        >
          200
        </StyledButton>
      </div>}
    </div>
  );
}

SelectType.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  types: PropTypes.array,
  placeholder: PropTypes.string,
  showQucikButtons: PropTypes.bool,
};
