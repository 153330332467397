import config from 'react-global-configuration';

const GA_BC_TOKEN = config.get('gaBCToken');
const GA_PC_TOKEN = config.get('gaPCToken');
const GA_LI_TOKEN = config.get('gaLIToken');

function sendEvent(eventName, additionalParams = {}) {
  window.gtag('event', eventName, { ...additionalParams });
}

export function sendBeginCheckoutEvent(value = 1.0, currency = 'USD', paymentMethod = '') {
  sendEvent(`begin_checkout_${paymentMethod}`, { value, currency });
  sendEvent('conversion', { 'send_to': GA_BC_TOKEN, value, currency });
}

export function sendPurchaseEvent(value = 1.0, currency = 'USD', paymentMethod = '', transactionId = '') {
  sendEvent(`purchase_${paymentMethod}`, { 'transaction_id': transactionId, 'value': value, 'currency': currency });
  sendEvent('conversion', { 'send_to': GA_PC_TOKEN, value, currency });
}

export function sendFailedPurchaseEvent(value = 1.0, currency = 'USD', paymentMethod = '') {
  sendEvent('failed_purchase');
  sendEvent(`failed_purchase_${paymentMethod}`, { 'send_to': GA_PC_TOKEN, value, currency });
}

export function sendSignUpEvent() {
  sendEvent('sign_up');
  sendEvent('conversion', { 'send_to': GA_LI_TOKEN });
}

export function sendLoginEvent() {
  sendEvent('login');
  sendEvent('conversion', { 'send_to': GA_LI_TOKEN });
}
